
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import dynamic from 'next/dynamic';
import Head from 'next/head';
import { NextAdapter } from 'next-query-params';
import withYM from 'next-ym';
import { Provider } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import Notification from '@components/Common/Notification';
import { queryClient, QueryClientProvider } from '@lib/cache-query-client';
import { useSyncDayjsLocaleWithCookie } from '@lib/datetime';
import '@lib/firebase';
import { Router } from '@lib/i18n';
import '@lib/icons';
import { useStore } from '@store';
import '@styles/global.scss';
import '@styles/print.scss';
const ReactTooltipWrapper = dynamic(() => import('react-tooltip'), { ssr: false });
const AuthModal = dynamic(() => import('@components/Common/AuthModule'), { ssr: false });
const MyApp = (props) => {
    const { Component, pageProps } = props;
    const { initialReduxState } = pageProps;
    const store = useStore(initialReduxState);
    useSyncDayjsLocaleWithCookie();
    return (<>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            </Head>

            <QueryClientProvider client={queryClient}>
                <Provider store={store}>
                    <QueryParamProvider adapter={NextAdapter}>
                        <Component {...pageProps}/>

                        <ReactTooltipWrapper uuid="global" type="dark" effect="solid" delayShow={300} multiline={true} arrowColor="transparent"/>

                        <AuthModal />

                        <Notification />
                    </QueryParamProvider>
                </Provider>
            </QueryClientProvider>
        </>);
};
export let getStaticProps = undefined;
const __Next_Translate__Page__195d70e1304__ = withYM('56937652', Router)(MyApp);

    export default __appWithI18n(__Next_Translate__Page__195d70e1304__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  